.slick-dots,
.slick-next,
.slick-prev {
    position: absolute;
    display: block;
    padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* .slick-loading .slick-list {
    background: url(ajax-loader.gif) center center no-repeat #fff;
} */
/* @font-face {
    font-family: slick;
    font-weight: 400;
    font-style: normal;
    src: url(fonts/slick.eot);
    src: url(fonts/slick.eot?#iefix) format("embedded-opentype"), url(fonts/slick.woff) format("woff"), url(fonts/slick.ttf) format("truetype"), url(fonts/slick.svg#slick) format("svg");
} */
.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    top: 38%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    z-index: 9;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
    font-size: 25px;
    line-height: 1;
    opacity: 1;
    color: #181D21;
    background-color: transparent;
    padding: 2px 10px 5px 9px;
}
.slick-prev {
    left: 48%;
}
[dir="rtl"] .slick-prev {
    right: 0px;
    left: auto;
}
.slick-prev:before {
    font-family: "Material Symbols Outlined";
    content: "\e5c4";
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
    font-family: "Material Symbols Outlined";
    content: "\e5c8";
}
.slick-next {
    right: 35px;
}
[dir="rtl"] .slick-next {
    right: auto;
    left: 0px;
}
[dir="rtl"] .slick-next:before {
    font-family: "Material Symbols Outlined";
    content: "\e5c8";
}
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-size: 40px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #000;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
}


/* Página Nossa Frota */
#frota .slick-dots {
    bottom: 25px;
}
#frota .slick-dots li button:before {
    opacity: 0.25;
    color: #fff;
}
#frota .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
}
#frota .slick-slide img {
    padding: 0;
}
#frota .slick-next::before,
#frota .slick-prev::before {
    color: #fff;
}
#frota .slick-next {
    top: 50%;
    right: 40px;
}
#frota .slick-prev {
    top: 50%;
    left: 20px;
}

/* Componente Modelo */
#modelos .slick-dots {
    top: 0;
    bottom: auto;
    right: 0;
    width: 50%;
}
#modelos .slick-dots li {
    width: 30%;
    height: 43px;
}
#modelos .slick-dots li.slick-active {
    background-color: #F1F1F1;
    color: #0087FB;
}
#modelos .slick-dots li.slick-active button:before{
    color: #0087FB;
}
#modelos .slick-dots li button,
#modelos .slick-dots li button:before {
    width: 100%;
    height: auto;
}
#modelos .slick-dots button:before {
    font-family: "Lato";
    font-size: 1rem;
    font-weight: 600;
    color: #6F6F78;
    padding-top: 10px;
    opacity: 1;
}
#modelos .slick-dots li:nth-of-type(1) button:before {
    content: "Modelo Hatch";
}
#modelos .slick-dots li:nth-of-type(2) button:before {
    content: "Modelo Sedan";
}
#modelos .slick-dots li:nth-of-type(3) button:before {
    content: "Modelo SUV";
}

.carrossel {
    width: 100%;
}
.carrossel .slick-prev {
    top: auto;
    bottom: -15%;
    left: 47%;
}
.carrossel .slick-next {
    top: auto;
    bottom: -15%;
    right: 47%;
}

/* Versão Mobile */
@media (max-width: 780px) {
    .slick-next, .slick-prev {
        top: 32%;
    }
    .slick-prev {
        left: 3%;
    }
    .slick-dots {
        display: none !important;
    }
    .carrossel .slick-prev {
        left: 33%;
        bottom: 0;
    }
    .carrossel .slick-next {
        right: 40%;
        bottom: 0;
    }
}